//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    teamValue: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      selLoading: false,
      loading: false,
      options: [],
      dialogFormVisible: false,
      form: {
        tcId: null,
      },
    };
  },
  methods: {
    /**
     * 打开弹窗
     */
    openModal() {
      this.dialogFormVisible = true;
      this.form.tcId = null;
      this.$nextTick(() => {
        this.getSystemSel();
      });
    },
    /**
     * 确认
     */
    handleConfrim() {
      if (!this.form.tcId) {
        this.$message({
          showClose: true,
          message: "请选择系统配置组！",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      const data = {
        teamId: this.teamValue,
        tcId: this.form.tcId,
      };
      this.$http
        .post("/ConfigGroup/ApplySystemConfig.ashx", data)
        .then((resp) => {
          if (resp.res == 0) {
            this.dialogFormVisible = false;
            this.$emit("success");
          }
        })
        .finally(() => (this.loading = false));
    },
    /**
     *获取系统配置选项
     */
    getSystemSel() {
      this.selLoading = true;
      this.$http
        .post("/ConfigGroup/GetTeamConfigSelect.ashx", {
          teamId: this.teamValue,
        })
        .then((resp) => {
          if (resp.res == 0) {
            this.options = resp.data;
          }
        })
        .finally(() => (this.selLoading = false));
    },
  },
};
